<div class="mat-typography">
  <section
    class="hero"
    style="
      background-image: url('assets/images/mokki_1/mokki_1_18_converted.png');
    "
  >
    <div class="hero-content oswald">
      <h1 class="mat-headline-1">Panosta henkilöstösi työhyvinvointiin Himoksen upeissa maisemissa</h1>
      <div class="mat-headline-2">
        <p>
          Työhyvinvoinnista huolehtiminen ei ole enää vain etu – se on avain
          yrityksen menestykseen.
        </p>
        <p>
          Henkilöstö, joka voi hyvin, on sitoutuneempi, tuottavampi ja
          tyytyväisempi työpaikallaan.
        </p>
        <p>
          Tarjoamme yrityksellenne ainutlaatuisen mahdollisuuden parantaa
          henkilöstönne hyvinvointia pitkäaikaisella mökkivuokrauksella Himoksen
          huippuluokan resort-alueella.
        </p>
      </div>
    </div>
  </section>
  <mat-grid-list cols="1" [rowHeight]="windowWidth < 1000 ? '100vh' : '45vh'" gutterSize="0">
    <mat-grid-tile style="background-color: #fff3e4">
      <div
        class="max-width-50 container-center-column"
        style="align-items: center"
      >
        <mat-card-header>
          <mat-card-title class="mat-headline-4">Mitä tarjoamme?</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p class="mat-headline-6"><strong>Hotellitasoista luksusta luonnon keskellä</strong></p>
          <p>
            Mökkimme sijaitsee Himoksen uudella golf-resortin alueella, joka
            tarjoaa ensiluokkaiset puitteet rentoutumiseen ja virkistäytymiseen.
          </p>
          <p>
            Tilavat, hotellitasoiset mökit ovat täydellisesti varusteltuja, ja
            ne tarjoavat viihtyisät olosuhteet niin työntekoon kuin vapaa-ajan
            viettoon.
          </p>
          <p>
            Mökkien moderni varustelutaso sekä tyylikäs sisustus tekevät
            vierailusta nautinnollisen elämyksen, jossa yhdistyvät kodinomaisuus
            ja hotellin mukavuudet.
          </p>
        </mat-card-content>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-grid-list [cols]="windowWidth < 1000 ? 1 : 2" [rowHeight]="windowWidth < 1000 ? '125vh' : '50vh'" gutterSize="0">
    <mat-grid-tile *ngIf="windowWidth > 1000"
      style="
        background-image: url(https://cdn.pixabay.com/photo/2018/09/18/09/30/golf-3685616_1280.jpg);
        background-size: cover;
      "
    >
    </mat-grid-tile>
    <mat-grid-tile style="background-color: rgb(216 255 214)">
      <div class="container-center-column">
        <mat-card-header>
          <mat-card-title class="mat-headline-4">Aktiviteetit osana palvelupakettia</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>
            Himos tunnetaan monipuolisista vapaa-ajan mahdollisuuksistaan ympäri
            vuoden. Mökkivuokraukseen sisältyvät erilaiset aktiviteettiedut,
            jotka tuovat lisäarvoa työntekijöiden vapaa-aikaan. Peruspakettiimme
            kuuluu mm.:
          </p>
          <ul>
            <li>
              <strong>Hissiliput</strong> talvikauden laskettelijoille ja
              lumilautailijoille
            </li>
            <li>
              <strong>Golfpelioikeudet</strong> kesän harrastajille Himoksen
              upeilla golfkentillä
            </li>
            <li>
              <strong>Lähialueen retkeily- ja ulkoiluaktiviteetit,</strong>
              kuten pyöräily ja vaellus
            </li>
          </ul>
          <p>
            Olipa vuodenaika mikä tahansa, Himoksella löytyy jokaiselle jotain.
          </p>
        </mat-card-content>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-grid-list cols="1" [rowHeight]="windowWidth < 1000 ? '90vh' : '55vh'" gutterSize="0">
    <mat-grid-tile style="background-color: #fff3e4">
      <div
        class="max-width-50 display-flex center"
        [style.flex-direction]="windowWidth < 1000 ? 'column' : 'row-reverse'"
        style="align-items: center;"
      >
        <mat-card-header>
          <mat-card-title class="mat-headline-4"
            >Hallinnoitu varauskalenteri – yksinkertaista ja
            vaivatonta</mat-card-title
          >
        </mat-card-header>
        <mat-card-content>
          <p>
            Yrityksenne ei tarvitse huolehtia käytännön järjestelyistä.
            Tarjoamme mökille hallinnoidun varauskalenterin, joka mahdollistaa
            helpon ja tehokkaan varausjärjestelmän yrityksellenne.
          </p>
          <p>
            Kalenteri on räätälöity niin, että työntekijät voivat helposti
            tarkistaa mökin saatavuuden ja tehdä varauksen vaivattomasti.
          </p>
          <p>
            Näin yrityksenne hallinto voi keskittyä olennaiseen, ja mökin
            käyttöönottaminen sujuu saumattomasti.
          </p>
        </mat-card-content>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-grid-list [cols]="windowWidth < 1000 ? 1 : 2" [rowHeight]="windowWidth < 1000 ? '150vh' : '80vh'" gutterSize="0">
    <mat-grid-tile>
      <div class="container-center-column">
        <mat-card-header>
          <mat-card-title class="mat-headline-4">Miksi valita juuri meidät?</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p class="mat-headline-6">
            <strong>Työhyvinvointia uudella tavalla</strong>
          </p>
          <p>
            Nykyajan kiireisessä työelämässä työnantajat etsivät yhä useammin
            uusia keinoja tarjota työntekijöilleen mahdollisuutta palautumiseen
            ja virkistäytymiseen.
          </p>
          <p>
            Luksusmökin vuokraus Himoksella tarjoaa yrityksellenne
            työhyvinvointiedun, joka on enemmän kuin perinteinen liikuntaseteli
            tai toimistolle tuodut hedelmät – se on kokemus, joka yhdistää
            tiimejä, motivoi henkilöstöä, luo muistoja ja auttaa heitä
            latautumaan uudella tavalla.
          </p>
          <p class="mat-headline-6"><strong>Tehokas tapa sitouttaa ja palkita henkilöstöä</strong></p>
          <p>
            Mökki on loistava tapa palkita parhaiten suoriutuneita työntekijöitä
            tai järjestää yrityksen virkistys- ja tiimipäiviä.
          </p>
          <p>
            Se on myös erinomainen tapa houkutella uusia huippuosaajia, kun
            yrityksellänne on tarjota tällaisia ainutlaatuisia etuja.
          </p>
          <p>
            Työntekijöiden motivaatio kasvaa, kun he tietävät, että yritys
            todella välittää heidän hyvinvoinnistaan.
          </p>
        </mat-card-content>
      </div>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="windowWidth > 1000"
      style="
        background-image: url(https://cdn.pixabay.com/photo/2015/03/01/16/52/puzzle-654956_1280.jpg);
      "
    />
  </mat-grid-list>
  <mat-grid-list cols="1" rowHeight="20vh" gutterSize="0">
    <mat-grid-tile style="background-color: rgb(207, 255, 228)">
      <div>
        <mat-card-header>
          <mat-card-title class="mat-headline-4"> Visio ja tavoitteet </mat-card-title>
        </mat-card-header>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-grid-list
    style="margin-top: 16px"
    [cols]="windowWidth < 1000 ? 1 : 3"
    [rowHeight]="windowWidth < 1000 ? '125vh' : '40vh'"
    gutterSize="25px"
  >
    <mat-grid-tile>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            style="background-color: rgb(213 253 232)"
          >
            <mat-panel-title mat-card-title
              >Laajentuminen ja valikoiman kehittyminen</mat-panel-title
            >
          </mat-expansion-panel-header>
          <p>
            Ensimmäinen askel matkallamme kohti visiomme toteutumista on
            mökkivalikoimamme laajentaminen Himoksen alueella. Tavoitteenamme on
            tarjota entistä enemmän korkealaatuisia mökkivaihtoehtoja, jotka
            täyttävät vaativimmatkin tarpeet, ja tuoda samalla lisää erilaisia
            aktiviteettipaketteja ja mukavuuksia asiakkaillemme. Haluamme
            mukautua asiakkaidemme toiveisiin ja odotuksiin, kehittäen
            palveluamme vastaamaan alati muuttuvia työelämän ja vapaa-ajan
            vaatimuksia. Meille on tärkeää, että voimme tarjota
            asiakasyrityksillemme yksilöllisiä, mutta samalla joustavia
            palveluratkaisuja. Tämä tarkoittaa jatkuvaa innovointia ja
            reagointia nykyisiin trendeihin, kuten etätyöhön, hyvinvoinnin
            merkityksen kasvuun ja ihmisten kaipuuseen luonnonläheisiin
            elämyksiin.
          </p>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            style="background-color: rgb(213 253 232)"
          >
            <mat-panel-title mat-card-title
              >Laajeneminen Suomessa ja ulkomailla</mat-panel-title
            >
          </mat-expansion-panel-header>
          <p>
            Visiomme ulottuu myös Himoksen ulkopuolelle. Tulevaisuuden
            tavoitteenamme on laajentua muihin Suomen suosituimpiin
            vapaa-ajanviettokohteisiin sekä myöhemmin kansainvälisille
            lomakohteille.
          </p>
          <p>
            Haluamme tarjota asiakasyrityksillemme mahdollisuuden monipuolistaa
            ja rikastaa työhyvinvointiin liittyvää lomailua vaihtamalla
            vuokrattavaa kohdetta vuosittain.
          </p>
          <p>
            Laajentumisen myötä asiakasyrityksillämme olisi mahdollisuus kokea
            useita erilaisia ja houkuttelevia kohteita – vuosi toisensa jälkeen.
            Tämä mahdollisuus avaa uusia tapoja hyödyntää työhyvinvointietuja,
            kun työntekijät pääsevät nauttimaan uusista kohteista niin
            kotimaassa kuin ulkomaillakin.
          </p>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            style="background-color: rgb(213 253 232)"
          >
            <mat-panel-title mat-card-title
              >Tulevaisuuden palvelutarjonnan kehittäminen</mat-panel-title
            >
          </mat-expansion-panel-header>
          <p>
            Nykymaailmassa asiakasodotukset ja työelämän tarpeet muuttuvat
            nopeaan tahtiin. Olemme sitoutuneet kehittämään palveluitamme
            jatkuvasti ja adaptoitumaan niin teknologian kuin työelämän
            muutoksiin.
          </p>
          <p>
            Haluamme tulevaisuudessa tarjota entistä räätälöidympiä
            palvelupaketteja, joissa korostuu mukautuminen yritysasiakkaidemme
            yksilöllisiin tarpeisiin. Palvelumme laajentuu jatkossa esimerkiksi
            älykkäiden varausjärjestelmien ja digitaalisten
            hyvinvointiratkaisujen osalta.
          </p>
          <p>
            Tämä tarkoittaa muun muassa digitaalisia etäkokousmahdollisuuksia ja
            työpäivien lomassa tapahtuvaa hyvinvointipalvelujen integrointia.
          </p>
          <p>
            Lisäksi pyrimme lisäämään ympäristöystävällisiä ratkaisuja, kuten
            kestävää matkailua tukevia mökkikonsepteja ja energiatehokkaita
            rakenteita, jotka tukevat asiakkaidemme arvoja.
          </p>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-grid-list
    style="margin-top: 16px"
    [cols]="windowWidth < 1000 ? 1 : 2"
    [rowHeight]="windowWidth < 1000 ? '125vh' : '40vh'"
    gutterSize="25px"
  >
    <mat-grid-tile>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            style="background-color: rgb(213 253 232)"
          >
            <mat-panel-title mat-card-title
              >Himos – monipuolinen vapaa-ajanviettopaikka ympäri
              vuoden</mat-panel-title
            >
          </mat-expansion-panel-header>
          <p>
            Himoksen alue on tunnettu siitä, että se tarjoaa loistavat
            mahdollisuudet virkistäytymiseen kaikkina vuodenaikoina. Kesällä
            Himoksen vehreät golfkentät ja lukuisat ulkoilureitit kutsuvat
            luonnon helmaan. Talvella alue muuttuu talviurheilun mekaksi,
            tarjoten upeat laskettelumahdollisuudet Himoksen rinteillä. Lisäksi
            alueen monipuoliset palvelut, ravintolat ja tapahtumat tekevät siitä
            täydellisen kohteen yrityksille, jotka haluavat tarjota
            työntekijöilleen ikimuistoisia elämyksiä.
          </p>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header
            style="background-color: rgb(213 253 232)"
          >
            <mat-panel-title mat-card-title>Huvilamme</mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Vuonna 2022 valmistunut moderni huvila uudella Himoksen
            Golf-Resortin alueella. Huvilassa on huoneistoa kohden kolme
            makuuhuonetta eli tilat 6 + 2 henkilölle sekä oma ulkoporeallas.
            Kohteen sisältöä on myös mahdollista räätälöidä yrityksenne
            tarpeiden mukaisesti. Esimerkkipaketti sisältää:
          </p>
          <ul>
            <li>✓ Automaattinen varauskalenteri</li>
            <li>✓ Porealtaan vapaa käyttö</li>
            <li>✓ 2 kpl Hissiliput (kausikortit)</li>
            <li>✓ 2 kpl Golf pelioikeus (kausikortit)</li>
            <li>✓ Lähtösiivoukset ja porehuollot!</li>
          </ul>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-grid-list style="margin-top: 4em;" [cols]="windowWidth < 1000 ? 1 : 2"  [rowHeight]="windowWidth < 1000 ? '200vh' : '120vh'" gutterSize="0">
    <mat-grid-tile>
      <div class="container-center-column">
        <mat-card-header>
          <mat-card-title class="mat-headline-4"
            >Työhyvinvointi-investointi, joka maksaa itsensä
            takaisin</mat-card-title
          >
        </mat-card-header>
        <mat-card-content>
          <p>
            Palvelumme on suunniteltu mahdollisimman vaivattomaksi ja
            kustannustehokkaaksi ratkaisuksi yrityksille, jotka haluavat tarjota
            työntekijöilleen ensiluokkaista työhyvinvointia ja etuja. Sen
            sijaan, että yrityksenne joutuisi investoimaan satoja tuhansia
            euroja oman mökin hankintaan ja huolehtimaan jatkuvista ylläpito- ja
            huoltokustannuksista, tarjoamme valmiin ja huolettoman vaihtoehdon.
            Palvelumme avulla yritys säästää paitsi huomattavat summat, myös
            aikaa ja resursseja, joita kuluisi kiinteistön hallintaan,
            kalustamiseen ja sen kunnossapitoon. Mökin vuokraamiseen ei liity
            kiinteistön omistamisen riskejä, kuten remonttitarpeita tai
            huoltoja, ja yrityksenne voi olla varma siitä, että kaikki hoidetaan
            puolestanne. Hotellitasoinen mökki on aina valmiina, ja me
            huolehdimme myös varauskalenterin hallinnoinnista, jotta kaikki
            sujuu joustavasti ja ilman ylimääräistä vaivaa. Hyvinvoiva
            työntekijä on sitoutuneempi, vähemmän stressaantunut ja tuottavampi.
            Vuokraamalla mökkimme Himoksen ainutlaatuisella alueella,
            yrityksenne saa työhyvinvointiratkaisun, joka on sekä
            kustannustehokas että helposti toteutettavissa. Tämä on investointi,
            joka maksaa itsensä takaisin parempana työilmapiirinä, pienempänä
            henkilöstön vaihtuvuutena ja lisääntyneenä motivaationa.
          </p>
        </mat-card-content>
        <mat-card-header style="margin-top: 4em;">
          <mat-card-title class="mat-headline-4">Ota yhteyttä ja pyydä tarjous</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>
            Ota yhteyttä meihin, niin räätälöimme juuri teidän yrityksellenne
            sopivan mökkivuokrauspaketin. Vastaamme mielellämme kaikkiin
            kysymyksiin ja autamme tekemään yrityksenne
            työhyvinvointi-investoinnista helppoa ja vaivatonta.
          </p>
        </mat-card-content>
      </div>
    </mat-grid-tile>
    <mat-grid-tile
      style="
        background-image: url(assets/images/mokki_1/kollaasi.png);
        background-size: cover;
      "
    >
    </mat-grid-tile>
  </mat-grid-list>
  <mat-card class="sponsor-wrapper">
    <mat-card-header>
      <mat-card-title>Yhteistyökumppanit</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content class="sponsors">
      <img
        src="assets/images/logot/voittamaton-logo.png"
        alt="Voittamaton Oy"
      />
      <div>
        <img src="assets/images/logot/bemaster-logo.webp" alt="Bemaster" />
        <img
          src="assets/images/logot/bepop-logo.webp"
          alt="Asiantuntijakeskus BePop"
        />
      </div>
    </mat-card-content>
  </mat-card>
</div>
