<div class="container-center-column">
  <div class="loading" *ngIf="loading">
    <mat-spinner diameter="100"></mat-spinner>
  </div>
  <div class="container center-div" *ngIf="fetchFailed; else success">
    <mat-card class="center container-center-column" style="height: 50vh;">
      <mat-card-title>Virhe varauksia haettaessa</mat-card-title>
      <mat-card-content>
        <p>Varauskalenterin lataaminen epäonnistui.</p>
        <p>Yritä päivittää sivu uudelleen.</p>
      </mat-card-content>
    </mat-card>
  </div>
  <ng-template #success>
    <mat-card class="container-center-column" style="width: 100%;" *ngIf="!loading">
      <mat-card-header class="header center">
        <mat-card-title>Varauskalenteri</mat-card-title>
        <mat-card-subtitle>
          {{ getMonthString(currentViewMonth) }}
          {{ getYearString(currentViewMonth) }}
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-actions class="button-container">
        <button
          mat-raised-button
          color="accent"
          (click)="changeMonth(-1)"
          [disabled]="disablePrevious"
        >
          Edellinen
        </button>
        <button
          mat-raised-button
          color="accent"
          (click)="changeMonth(1)"
          [disabled]="disableNext"
        >
          Seuraava
        </button>
      </mat-card-actions>
      <mat-divider></mat-divider>
      <mat-form-field appearance="fill">
        <input
          matInput
          [value]="selectedDate"
          [matDatepicker]="picker"
          [min]="today.toDate()"
          [max]="maxDate.toDate()"
          placeholder="Valitse päivämäärä"
          (dateChange)="onDateSelected($event)"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </mat-card>
    <mat-grid-list
      cols="7"
      rowHeight="100px"
      *ngFor="let week of month"
    >
      <mat-grid-tile *ngFor="let day of week" colspan="1">
        <app-day
          class="day center"
          [date]="day"
          (dateSelected)="onDateSelected({ value: $event })"
        ></app-day>
      </mat-grid-tile>
    </mat-grid-list>
  </ng-template>
</div>
